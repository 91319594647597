import 'vuetify/styles';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {VDateInput} from 'vuetify/labs/VDateInput';
import {aliases} from 'vuetify/iconsets/mdi-svg';
import approved from '@/components/svg/approved';
import Capitalist from '@/components/svg/Capitalist';
import calendar from '@/components/svg/calendar';
import ExportIcon from '@/components/svg/ExportIcon';
import Card from '@/components/svg/Card';
import NewPayPal from '@/components/svg/NewPayPal';
import AnalyticsTemplate from '@/components/svg/AnalyticsTemplate';
import LBPgear from '@/components/svg/LBPgear';
import LBPtime from '@/components/svg/LBPtime';
import LBPanchor from '@/components/svg/LBPanchor';
import copyData from '@/components/svg/copyData';
import refreshData from '@/components/svg/refreshData';
import arrowShuffle from '@/components/svg/arrowShuffle';
import PBNGear from '@/components/svg/PBNGear';
import PBNStack from '@/components/svg/PBNStack';
import PBNTime from '@/components/svg/PBNTime';
import PBNWarning from '@/components/svg/PBNWarning';
import searchGreyIcon from '@/components/svg/searchGreyIcon';
import licenseLogo from '@/components/svg/licenseLogo';
import management from '@/components/svg/management';
import check from '@/components/svg/check';
import PBNPerformance from '@/components/svg/PBNPerformance';
import ProjectContent from '@/components/svg/ProjectContent';
import UsersActivity from '@/components/svg/UsersActivity';
import UsersPerformance from '@/components/svg/UsersPerformance';
import PassportReport from '@/components/svg/PassportReport';
import KeywordsReport from '@/components/svg/KeywordsReport';
import Conversions from '@/components/svg/Conversions';
import RlTop from '@/components/svg/RlTop';
import tooltipViolet from '@/components/svg/tooltipViolet';
import CPWarning from '@/components/svg/CPWarning';
import checkboxMinus from '@/components/svg/checkboxMinus';
import refresh from '@/components/svg/refresh';
import menuMarketingPlan from '@/components/svg/menuMarketingPlan';
import menuOrders from '@/components/svg/menuOrders';
import CPArticles from '@/components/svg/CPArticles';
import CPUpdate from '@/components/svg/CPUpdate';
import CPСopywriter from '@/components/svg/CPСopywriter';
import copywriters from '@/components/svg/copywriters';
import CPworks from '@/components/svg/CPworks';
import CPSpending from '@/components/svg/CPSpending';
import user from '@/components/svg/user';
import RadioOn from '@/components/svg/RadioOn';
import RadioOff from '@/components/svg/RadioOff';
import cardsmall from '@/components/svg/cardsmall';
import btcsmall from '@/components/svg/btcsmall';
import LBOBcard from '@/components/svg/LBOBcard';
import LBOBplanet from '@/components/svg/LBOBplanet';
import LBOBstack from '@/components/svg/LBOBstack';
import PayPal from '@/components/svg/PayPal';
import URLProfiler from '@/components/svg/URLProfiler';
import UploadData from '@/components/svg/UploadData';
import Upload from '@/components/svg/Upload';
import success from '@/components/svg/success';
import errorIcon from '@/components/svg/errorIcon';
import brokenLine from '@/components/svg/brokenLine.vue';
import update from '@/components/svg/update.vue';
import lbTarget from '@/components/svg/lbTarget.vue';
import lbReferring from '@/components/svg/lbReferring.vue';
import telegram from '@/components/svg/telegram.vue';
import phone from '@/components/svg/phone.vue';
import logout from '@/components/svg/logout.vue';
import gmail from '@/components/svg/gmail.vue';
import OrderItemIcon from '@/components/svg/OrderItemIcon.vue';
import HeatmapRD from '@/components/svg/HeatmapRD';
import HeatmapURL from '@/components/svg/HeatmapURL';
import Tier from '@/components/svg/Tier';
import Economics from '@/components/svg/Economics';
import Crossmap from '@/components/svg/Crossmap';
import ContentAudit from '@/components/svg/ContentAudit';
import Budgets from '@/components/svg/Budgets';
import Backlink from '@/components/svg/Backlink';
import BacklinkDeleted from '@/components/svg/BacklinkDeleted';
import Summary from '@/components/svg/Summary';
import GroupIcon from '@/components/svg/GroupIcon';
import ProjectGroupIcon from '@/components/svg/ProjectGroupIcon';
import ProjectIcon from '@/components/svg/ProjectIcon';
import Usd from '@/components/svg/Usd';
import CardRus from '@/components/svg/CardRus';
import CardUa from '@/components/svg/CardUa';
import Bitcoin from '@/components/svg/Bitcoin';
import Eth from '@/components/svg/Eth';
import Fiverr from '@/components/svg/Fiverr';
import WirePay from '@/components/svg/WirePay';
import UploadLink from '@/components/svg/UploadLink';
import Ga from '@/components/svg/Ga';
import itemDollar from '@/components/svg/itemDollar';
import itemFolders from '@/components/svg/itemFolders';
import itemTasks from '@/components/svg/ItemTasks';
import company from '@/components/svg/company';
import NEW_PAGE_TRANSLATE from '@/components/svg/NEW_PAGE_TRANSLATE';
import PAGE_META_CHANGED from '@/components/svg/PAGE_META_CHANGED';
import PAGE_CONTENT_CHANGED from '@/components/svg/PAGE_CONTENT_CHANGED';
import PAGE_CONTENT_PLANNED from '@/components/svg/PAGE_CONTENT_PLANNED';
import CopyIcon from '@/components/svg/CopyIcon';
import spamDownload from '@/components/svg/spamDownload';
import approvedUser from '@/components/svg/approvedUser';
import rejected from '@/components/svg/rejected';
import btnPlus from '@/components/svg/btnPlus';
import btnSend from '@/components/svg/btnSend';
import inprogress from '@/components/svg/inprogress';
import itemComplete from '@/components/svg/itemComplete';
import loggedUsers from '@/components/svg/loggedUsers';
import profilerOperations from '@/components/svg/profilerOperations';
import profilerPosition from '@/components/svg/profilerPosition';
import profilerMainKeyword from '@/components/svg/profilerMainKeyword';
import profilerKeywords from '@/components/svg/profilerKeywords';
import global from '@/components/svg/global';
import arrowLeft from '@/components/svg/arrowLeft';
import arrowRight from '@/components/svg/arrowRight';
import tooltip from '@/components/svg/tooltip';
import paymentDetails from '@/components/svg/paymentDetails';
import relatedTo from '@/components/svg/relatedTo';
import searchDark from '@/components/svg/searchDark.vue';
import brefProjectStart from '@/components/svg/brefProjectStart.vue';
import brefUrlStart from '@/components/svg/brefUrlStart.vue';
import dots from '@/components/svg/dots.vue';
import btnApply from '@/components/svg/btnApply.vue';
import btnEdit from '@/components/svg/btnEdit.vue';
import btnDelete from '@/components/svg/btnDelete.vue';
import btnCancel from '@/components/svg/btnCancel.vue';
import menuContentMarketing from '@/components/svg/menuContentMarketing';
import menuAffiliateMarketing from '@/components/svg/menuAffiliateMarketing';
import menuFinance from '@/components/svg/menuFinance';
import menuInsights from '@/components/svg/menuInsights';
import menuLinkBuilding from '@/components/svg/menuLinkBuilding';
import menuMaintenance from '@/components/svg/menuMaintenance';
import menuMyDashboard from '@/components/svg/menuMyDashboard';
import menuReports from '@/components/svg/menuReports';
import menuSemantics from '@/components/svg/menuSemantics';
import menuTeam from '@/components/svg/menuTeam';
import menuTools from '@/components/svg/menuTools';
import notification from '@/components/svg/notification';
import Logo from '@/components/svg/Logo';
import searchIcon from '@/components/svg/searchIcon';
import overview from '@/components/svg/overview';
import backlinks from '@/components/svg/backlinks';
import newContent from '@/components/svg/newContent';
import updatedContent from '@/components/svg/updatedContent';
import moveUp from '@/components/svg/moveUp';
import moveDown from '@/components/svg/moveDown';
import chevronDown from '@/components/svg/chevronDown';
import chevronLeft from '@/components/svg/chevronLeft';
import chevronRight from '@/components/svg/chevronRight';
import burger from '@/components/svg/burger';
import close from '@/components/svg/close';
import Plus from '@/components/svg/Plus';
import Minus from '@/components/svg/Minus';
import Delete from '@/components/svg/Delete';
import DeleteDocument from '@/components/svg/DeleteDocument';
import edit from '@/components/svg/edit';
import AddNew from '@/components/svg/AddNew';
import save from '@/components/svg/save';
import cloud from '@/components/svg/cloud';
import tableSort from '@/components/svg/tableSort';
import tableSortUp from '@/components/svg/tableSortUp';
import tableSortDown from '@/components/svg/tableSortDown';
import checkbox from '@/components/svg/checkbox';
import checkboxOn from '@/components/svg/checkboxOn';
import googleLogo from '@/components/svg/googleLogo';
import fiverrLogo from '@/components/svg/fiverrLogo';
import dashboardOpen from '@/components/svg/dashboardOpen';
import dashboardClose from '@/components/svg/dashboardClose';
import snackSuccess from '@/components/svg/snackSuccess';
import snackError from '@/components/svg/snackError';
import snackWarning from '@/components/svg/snackWarning';
import snackInfo from '@/components/svg/snackInfo';
import traffic from '@/components/svg/traffic';
import keywords from '@/components/svg/keywords';
import content from '@/components/svg/content';
import spending from '@/components/svg/spending';
import income from '@/components/svg/income';
import profit from '@/components/svg/profit';
import PAGE_CREATED from '@/components/svg/PAGE_CREATED';
import PAGE_DELETED from '@/components/svg/PAGE_DELETED';
import PAGE_REDIRECTED from '@/components/svg/PAGE_REDIRECTED';
import CONTENT_PROOF_READING from '@/components/svg/CONTENT_PROOF_READING';
import CONTENT_UPDATE from '@/components/svg/CONTENT_UPDATE';
import CONTENT_NEW_PAGE from '@/components/svg/CONTENT_NEW_PAGE';
import LINK_NEW from '@/components/svg/LINK_NEW';
import LINK_DELETED from '@/components/svg/LINK_DELETED';
import LINK_PLANNED from '@/components/svg/LINK_PLANNED';
import settings from '@/components/svg/settings';

const vuetify = createVuetify({
	components: {
		...components,
		VDateInput,
	},
	directives,
	defaults: {
		global: {
			ripple: false,
		},
		VButton: {
			elevation: 0,
		},
	},
	display: {
		thresholds: {
			xs: 600,
			sm: 960,
			md: 1264,
			lg: 1780,
		},
	},
	theme: {
		themes: {
			light: {
				primary: '#8F20EF',
				secondary: '#091849',
			},
			dark: {
				primary: '#8F20EF',
				secondary: '#091849',
			},
		},
	},
	icons: {
		aliases: {
			...aliases,
			approved,
			menuContentMarketing,
			menuAffiliateMarketing,
			menuFinance,
			menuInsights,
			menuLinkBuilding,
			menuMaintenance,
			menuMyDashboard,
			menuReports,
			menuSemantics,
			menuTeam,
			menuTools,
			notification,
			Logo, // слишком маленькое
			searchIcon,
			overview,
			backlinks,
			newContent,
			updatedContent,
			moveUp,
			moveDown,
			chevronDown,
			chevronLeft,
			chevronRight,
			burger,
			close,
			Plus,
			Minus,
			Delete,
			DeleteDocument,
			edit,
			AddNew,
			save,
			cloud,
			tableSort,
			tableSortUp,
			tableSortDown,
			checkbox,
			checkboxOn,
			googleLogo,
			fiverrLogo,
			dashboardOpen,
			dashboardClose,
			snackSuccess,
			snackError,
			snackWarning,
			snackInfo,
			traffic,
			keywords,
			content,
			spending,
			income,
			profit,
			PAGE_CREATED,
			PAGE_DELETED,
			PAGE_REDIRECTED,
			CONTENT_PROOF_READING,
			CONTENT_UPDATE,
			CONTENT_NEW_PAGE,
			LINK_NEW,
			LINK_DELETED,
			LINK_PLANNED,
			NEW_PAGE_TRANSLATE,
			PAGE_META_CHANGED,
			PAGE_CONTENT_CHANGED,
			PAGE_CONTENT_PLANNED,
			CopyIcon,
			spamDownload,
			approvedUser,
			rejected,
			btnPlus,
			btnSend,
			inprogress,
			itemComplete,
			loggedUsers,
			profilerOperations,
			profilerPosition,
			profilerMainKeyword,
			profilerKeywords,
			global,
			arrowLeft,
			arrowRight,
			tooltip,
			paymentDetails,
			relatedTo,
			searchDark,
			brefProjectStart,
			brefUrlStart,
			dots,
			btnApply,
			btnEdit,
			btnDelete,
			btnCancel,
			brokenLine,
			update,
			lbTarget,
			lbReferring,
			telegram,
			phone,
			logout,
			gmail,
			OrderItemIcon,
			HeatmapRD,
			HeatmapURL,
			Tier,
			Economics,
			Crossmap,
			ContentAudit,
			Budgets,
			Backlink,
			BacklinkDeleted,
			Summary,
			GroupIcon,
			ProjectGroupIcon,
			ProjectIcon,
			Usd,
			CardRus,
			CardUa,
			Bitcoin,
			Eth,
			Fiverr,
			Ga,
			WirePay,
			UploadLink,
			itemDollar,
			itemFolders,
			itemTasks,
			company,
			tooltipViolet,
			CPWarning,
			RlTop,
			Conversions,
			KeywordsReport,
			PassportReport,
			UsersPerformance,
			UsersActivity,
			ProjectContent,
			PBNPerformance,
			check,
			management,
			licenseLogo,
			searchGreyIcon,
			PBNWarning,
			PBNTime,
			PBNStack,
			PBNGear,
			arrowShuffle,
			refreshData,
			copyData,
			LBPanchor,
			LBPgear,
			LBPtime,
			NewPayPal,
			AnalyticsTemplate,
			Capitalist,
			calendar,
			ExportIcon,
			Card,
			checkboxMinus,
			refresh,
			menuMarketingPlan,
			menuOrders,
			CPArticles,
			CPUpdate,
			CPСopywriter,
			CPworks,
			CPSpending,
			success,
			errorIcon,
			UploadData,
			Upload,
			PayPal,
			URLProfiler,
			LBOBplanet,
			LBOBstack,
			btcsmall,
			LBOBcard,
			RadioOff,
			cardsmall,
			user,
			RadioOn,
			copywriters,
			settings,
		},
		sets: {},
	},
});

export default vuetify;
