import {createWebHistory, createRouter} from 'vue-router';
function coockieCheck() {
	let cookies = document.cookie.split(';');
	cookies = cookies.map((el) => {
		const index = el.indexOf('=');
		return index > -1 ? el.substring(0, index).trim() : el.trim();
	});
	return (
		cookies.includes('csrftoken') ||
    window.location.origin === 'http://localhost:8080'
	);
}
const routes = [
	{
		name: 'Insights',
		path: '/',
		component: () =>
			import(/* webpackChunkName: "insights" */ '@/views/Insights'),
		meta: {layout: 'MainLayout'},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		component: () =>
			import(/* webpackChunkName: "insights" */ '@/views/Insights.vue'),
		meta: {layout: 'MainLayout'},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/management',
		name: 'Management',
		component: () =>
			import(
				/* webpackChunkName: "management" */ '@/views/Management/index.vue'
			),
		meta: {
			layout: 'MainLayout',
			path: ['Management'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/management/pl',
		children: [
			{
				path: '/management/pl',
				component: () =>
					import(/* webpackChunkName: "pl" */ '@/views/Management/PL.vue'),
				name: 'PnL',
				meta: {
					layout: 'MainLayout',
					path: ['Management', 'PnL'],
					requiresChunkTimestamp: true,
				},
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				path: '/management/unit-economic',
				component: () =>
					import(
						/* webpackChunkName: "unitEconomics" */ '@/views/Management/UnitEconomics.vue'
					),
				name: 'Unit Economics',
				meta: {
					layout: 'MainLayout',
					path: ['Management', 'Unit Economics'],
					requiresChunkTimestamp: true,
				},
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				path: '/management/RevenueCabs',
				component: () =>
					import(
						/* webpackChunkName: "pl" */ '@/views/Management/RevenueCabs.vue'
					),
				name: 'Revenue Cabs',
				meta: {
					layout: 'MainLayout',
					path: ['Management', 'Revenue Cabs'],
					requiresChunkTimestamp: true,
				},
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
		],
	},
	{
		path: '/link-building',
		name: 'Link Building',
		component: () =>
			import(
				/* webpackChunkName: "linkBuilding" */ '@/views/LinkBuilding/index.vue'
			),
		meta: {layout: 'MainLayout', path: ['Link Building']},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/link-building/link-building-item',
		children: [
			{
				path: '/link-building/link-building-item',
				component: () =>
					import(
						/* webpackChunkName: "linkBuildingItem" */ '@/views/LinkBuilding/LinkbuildingItem.vue'
					),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Link Building Item',
				meta: {
					layout: 'MainLayout',
					path: ['Link Building', 'Link Building Item'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/reports',
				component: () =>
					import(/* webpackChunkName: "reports" */ '@/views/Reports.vue'),
				name: 'Reports',
				meta: {
					layout: 'MainLayout',
					path: [''],
					requiresChunkTimestamp: true,
				},
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) {
						next();
					} else {
						next('/login');
					}
				},
			},
			{
				path: '/link-building/accounts',
				component: () =>
					import(
						/* webpackChunkName: "accounts" */ '@/views/LinkBuilding/Accounts.vue'
					),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Accounts',
				meta: {
					layout: 'MainLayout',
					path: ['Link Building', 'Accounts'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/link-building/outreach-base',
				component: () =>
					import(
						/* webpackChunkName: "outreachBase" */ '@/views/LinkBuilding/OutreachBase.vue'
					),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Outreach Base',
				meta: {
					layout: 'MainLayout',
					path: ['Link Building', 'Outreach Base'],
					requiresChunkTimestamp: true,
				},
			},
		],
	},
	{
		path: '/orders',
		name: 'Orders',
		component: () => import(/* webpackChunkName: "orders" */ '@/views/Orders/index.vue'),
		meta: {layout: 'MainLayout', path: ['Orders'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/orders/orderitems',
		children: [
			{
				name: 'Order Items',
				meta: {layout: 'MainLayout', path: ['Orders', 'Order Items'], requiresChunkTimestamp: true},
				path: '/orders/orderitems',
				component: () => import(/* webpackChunkName: "orderItems" */ '@/views/Orders/OrderItems.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				name: 'Suppliers',
				meta: {layout: 'MainLayout', path: ['Orders', 'Suppliers'], requiresChunkTimestamp: true},
				path: '/orders/suppliers',
				component: () => import(/* webpackChunkName: "suppliers" */ '@/views/Orders/Suppliers.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
		],
	},
	{
		path: '/contentmarketing',
		name: 'Content Marketing',
		component: () => import(/* webpackChunkName: "contentMarketing" */ '@/views/ContentMarketing/index.vue'),
		meta: {layout: 'MainLayout', path: ['Content Items'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/contentmarketing/contentitems',
		children: [
			{
				path: '/contentmarketing/contentitems',
				name: 'Content Items',
				meta: {layout: 'MainLayout', path: ['Content Marketing', 'Content Items'], requiresChunkTimestamp: true},
				component: () => import(/* webpackChunkName: "contentItems" */ '@/views/ContentMarketing/ContentItems.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				path: '/contentmarketing/copywriters',
				name: 'Copywriters',
				meta: {layout: 'MainLayout', path: ['Content Marketing', 'Copywriters'], requiresChunkTimestamp: true},
				component: () => import(/* webpackChunkName: "copywriters" */ '@/views/ContentMarketing/Copywriters.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
		],
	},
	{
		path: '/marketing-plan',
		name: 'Marketing Plan',
		component: () => import(/* webpackChunkName: "marketingPlan" */ '@/views/MarketingPlan/index.vue'),
		meta: {layout: 'MainLayout', path: ['Marketing Plan'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/marketing-plan/content-plan',
		children: [
			{
				path: '/marketing-plan/content-plan',
				name: 'Content Plan',
				meta: {layout: 'MainLayout', path: ['Marketing Plan', 'Content Plan'], requiresChunkTimestamp: true},
				component: () => import(/* webpackChunkName: "contentPlan" */ '@/views/MarketingPlan/ContentPlan.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				path: '/marketing-plan/link-building-plan',
				name: 'Link Building Plan',
				meta: {layout: 'MainLayout', path: ['Marketing Plan', 'Link Building Plan'], requiresChunkTimestamp: true},
				component: () => import(/* webpackChunkName: "linkBuildingPlan" */ '@/views/MarketingPlan/LinkBuildingPlan.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
			{
				path: '/marketing-plan/pbn-plan',
				name: 'PBN Plan',
				meta: {layout: 'MainLayout', path: ['Marketing Plan', 'PBN Plan'], requiresChunkTimestamp: true},
				component: () => import(/* webpackChunkName: "pbnPlan" */ '@/views/MarketingPlan/PBNPlan'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
			},
		],
	},
	{
		path: '/brand-board',
		name: 'Brand Board',
		component: () => import(/* webpackChunkName: "about" */ '@/views/AffiliateMarketing/BrandBoard.vue'),
		meta: {layout: 'MainLayout'},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');		},
	},
	{
		path: '/monetization/byproject',
		component: () => import(/* webpackChunkName: "allStats" */ '@/views/AllStats.vue'),
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		name: 'By Project',
		meta: {layout: 'MainLayout', path: ['Monetization', 'By Project'], requiresChunkTimestamp: true},
	},
	{
		path: '/monetization/bybrand',
		component: () => import(/* webpackChunkName: "allStatsBrand" */ '@/views/AllStatsBrand.vue'),
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		name: 'By Brand',
		meta: {layout: 'MainLayout', path: ['Monetization', 'By Brand'], requiresChunkTimestamp: true},
	},
	{
		path: '/finance/tasks',
		component: () => import(/* webpackChunkName: "financeTasks" */ '@/views/Finance/FinanceTasks.vue'),
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		name: 'Tasks',
		meta: {layout: 'MainLayout', path: ['Finance', 'Tasks'], requiresChunkTimestamp: true},
	},
	{
		path: '/finance/balance',
		component: () => import(/* webpackChunkName: "balance" */ '@/views/Finance/Balance.vue'),
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		name: 'Balance',
		meta: {layout: 'MainLayout', path: ['Finance', 'Balance'], requiresChunkTimestamp: true},
	},
	{
		path: '/semantics',
		name: 'Semantics',
		component: () => import(/* webpackChunkName: "semantics" */ '@/views/Semantics.vue'),
		meta: {layout: 'MainLayout', requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/finance/my-cards',
		component: () => import(/* webpackChunkName: "myCards" */ '@/views/Finance/MyCards.vue'),
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		name: 'My Cards',
		meta: {layout: 'MainLayout', path: ['Finance', 'My Cards'], requiresChunkTimestamp: true},
	},
	{
		path: '/maintenance',
		name: 'Maintenance',
		component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance/index.vue'),
		meta: {layout: 'MainLayout', path: ['Maintenance'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/maintenance/projects',
		children: [
			{
				path: '/maintenance/tools',
				component: () => import( /* webpackChunkName: "tools" */ '@/views/Maintenance/Tools.vue' ),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Tools',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Tools'], requiresChunkTimestamp: true},
			},
			{
				path: '/maintenance/projects',
				component: () => import(/* webpackChunkName: "projects" */ '@/views/Maintenance/Projects.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Projects',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Projects'], requiresChunkTimestamp: true},
			},
			{
				path: '/maintenance/gsc-connections',
				component: () => import(/* webpackChunkName: "gscConnections" */ '@/views/Maintenance/GSCConnections.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Google Connections',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Google Connections'], requiresChunkTimestamp: true},
			},
			{
				path: '/maintenance/emails',
				component: () => import(/* webpackChunkName: "emails" */ '@/views/Maintenance/Emails.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Emails',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Emails'], requiresChunkTimestamp: true},
			},
			{
				path: '/maintenance/hostings-registrators',
				component: () => import(/* webpackChunkName: "hostingsRegistrators" */ '@/views/Maintenance/HostingsRegistrators.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Hostings & Registrators',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Hostings & Registrators'], requiresChunkTimestamp: true},
			},
			{
				path: '/maintenance/project-management',
				component: () => import(/* webpackChunkName: "projectManagement" */ '@/views/Maintenance/ProjectManagment.vue'),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Project Management',
				meta: {layout: 'MainLayout', path: ['Maintenance', 'Project Management'], requiresChunkTimestamp: true},
			},
		],
	},
	{
		path: '/brief',
		component: () =>
			import(/* webpackChunkName: "brief" */ '@/views/Brief/index.vue'),
		name: 'Brief',
		meta: {
			layout: 'MainLayout',
			path: ['Project', 'Brief Analytics'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
		redirect: '/brief/analytics',
		children: [
			{
				path: '/brief/analytics',
				component: () =>
					import(
						/* webpackChunkName: "analytics" */ '@/views/Brief/Analytics.vue'
					),
				beforeEnter: function(to, prev, next) {
					if (coockieCheck()) next();
					else next('/login');
				},
				name: 'Brief Analytics',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/traffic',
				component: () =>
					import(/* webpackChunkName: "traffic" */ '@/views/Brief/Traffic.vue'),
				name: 'Brief Traffic',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'Traffic'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/keyword-description',
				component: () =>
					import(
						/* webpackChunkName: "keywordDescription" */ '@/views/Brief/KeywordDescription.vue'
					),
				name: 'Brief Keyword Description',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'Keyword Description'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/ref-domains',
				component: () =>
					import(
						/* webpackChunkName: "refDomains" */ '@/views/Brief/RefDomains.vue'
					),
				name: 'Brief Ref Domains',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'Ref Domains'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/content',
				component: () =>
					import(/* webpackChunkName: "content" */ '@/views/Brief/Content.vue'),
				name: 'Brief Content',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'Content'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/costs',
				component: () =>
					import(/* webpackChunkName: "costs" */ '@/views/Brief/Costs.vue'),
				name: 'Brief Costs',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'Costs'],
					requiresChunkTimestamp: true,
				},
			},
			{
				path: '/brief/all-info',
				component: () =>
					import(/* webpackChunkName: "allInfo" */ '@/views/Brief/AllInfo.vue'),
				name: 'Brief All Info',
				meta: {
					layout: 'MainLayout',
					path: ['Project', 'Brief Analytics', 'All Info'],
					requiresChunkTimestamp: true,
				},
			},
		],
	},
	{
		path: '/profiler',
		name: 'Profiler',
		component: () =>
			import(/* webpackChunkName: "profiler" */ '@/views/Profiler.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Project', 'Profiler'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/spam-checker',
		name: 'Spam Checker',
		component: () =>
			import(/* webpackChunkName: "spamChecker" */ '@/views/SpamChecker.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Backlinks', 'Spam Checker'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/reuse-checker',
		name: 'Reuse Checker',
		component: () =>
			import(/* webpackChunkName: "reuseChecker" */ '@/views/ReuseChecker.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Backlinks', 'Reuse Checker'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/pbn',
		name: 'Link PBN',
		component: () => import(/* webpackChunkName: "pbn" */ '@/views/PBN.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Backlinks', 'Link PBN'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/anchor-analyzer',
		name: 'Anchor Analyzer',
		component: () =>
			import(
				/* webpackChunkName: "anchorAnalyzer" */ '@/views/AnchorAnalyzer.vue'
			),
		meta: {
			layout: 'MainLayout',
			path: ['Backlinks', 'Anchor Analyzer'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/heatmap',
		name: 'Heatmap',
		component: () =>
			import(/* webpackChunkName: "heatmap" */ '@/views/Heatmap.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Backlinks', 'Heatmap'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/keywords-checker',
		name: 'Keywords',
		component: () => import(/* webpackChunkName: "keywordsChecker" */ '@/views/KeywordsChecker.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Organic Search', 'Keywords Checker'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/top-pages',
		name: 'Top Pages',
		component: () =>
			import(/* webpackChunkName: "topPages" */ '@/views/TopPages.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Organic Search', 'Top Pages'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/content-gap',
		name: 'Content Gap',
		component: () => import(/* webpackChunkName: "contentGap" */ '@/views/ContentGap.vue'),
		meta: {layout: 'MainLayout', path: ['Organic Search', 'Content Gap'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/crossmap',
		name: 'Crossmap',
		component: () => import(/* webpackChunkName: "crossmap" */ '@/views/Crossmap.vue'),
		meta: {layout: 'MainLayout', path: ['Organic Search', 'Crossmap'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/overview',
		name: 'Overview',
		component: () => import(/* webpackChunkName: "overview" */ '@/views/Overview.vue'),
		meta: {layout: 'MainLayout', path: ['Organic Search', 'Overview'], requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/development/logs',
		name: 'Logs',
		component: () =>
			import(/* webpackChunkName: "logs" */ '@/views/Development/Logs.vue'),
		meta: {
			layout: 'MainLayout',
			path: ['Development', 'Logs'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/development/balance-dashboard',
		name: 'Balance Dashboard',
		component: () =>
			import(
				/* webpackChunkName: "balanceDashboard" */ '@/views/Development/BalanceDashboard'
			),
		meta: {
			layout: 'MainLayout',
			path: ['Development', 'Balance Dashboard'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/development/feature-flags',
		name: 'Feature Flags',
		component: () =>
			import(
				/* webpackChunkName: "featureFlags" */ '@/views/Development/FeatureFlags'
			),
		meta: {
			layout: 'MainLayout',
			path: ['Development', 'Feature Flags'],
			requiresChunkTimestamp: true,
		},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/team',
		name: 'Team',
		component: () => import(/* webpackChunkName: "team" */ '@/views/Team.vue'),
		meta: {layout: 'MainLayout', requiresChunkTimestamp: true},
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/login',
		name: 'Login',
		component: () =>
			import(/* webpackChunkName: "login" */ '../views/Login.vue'),
		meta: {layout: 'EmptyLayout', requiresChunkTimestamp: true},
	},
	{
		path: '/rejected',
		name: 'Rejected',
		meta: {layout: 'EmptyLayout', requiresChunkTimestamp: true},
		component: () =>
			import(/* webpackChunkName: "rejected" */ '../views/Rejected.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'not-found',
		component: () =>
			import(/* webpackChunkName: "notFound" */ '@/views/NotFound'),
	},
	{
		path: '/contentmarketing/contentitems/stepper',
		redirect: '/contentmarketing/contentitems',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/contentmarketing/copywriters/stepper',
		redirect: '/contentmarketing/copywriters',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/management/pl/stepper',
		redirect: '/management/pl',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/management/unit-economic/stepper',
		redirect: '/management/unit-economic',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/link-building/link-building-item/stepper',
		redirect: '/link-building/link-building-item',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/orders/suppliers/stepper',
		redirect: '/orders/suppliers',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/monetization/byproject/stepper',
		redirect: '/monetization/byproject',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/monetization/bybrand/stepper',
		redirect: '/monetization/bybrand',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/organic-search/crossmap/stepper',
		redirect: '/organic-search/crossmap/',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
	{
		path: '/semantics/stepper',
		redirect: '/semantics',
		beforeEnter: function(to, prev, next) {
			if (coockieCheck()) next();
			else next('/login');
		},
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});
router.onError((error) => {
	if (/ChunkLoadError:.*failed./i.test(error.message)) {
		window.location.reload();
	} else if (/Loading.*chunk.*failed./i.test(error.message)) {
		window.location.reload();
	}
});
router.beforeEach((to, from, next) => {
	const timestamp = new Date().getTime();
	if (to.matched.some((record) => record.meta.requiresChunkTimestamp)) {
		if (!to.query.timestamp) {
			to.query.timestamp = timestamp;
		} else {
			if (timestamp > parseInt(to.query.timestamp)) {
				to.query.timestamp = timestamp;
			}
		}
	}
	next();
});

router.afterEach((to, from, next) => {
	document.title = 'HubNero | ' + to.name;
});

export default router;
